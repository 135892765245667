import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
  currencyDropdownOptions,
  daysDropdownOptions,
  monthsDropdownOptions,
  offerNetworkOptions,
  offerTypeOptions,
  requestUserPaymentDetailsOptions,
} from '../../../common/util/dropdownUtil';
import HorizontalFormLabel from '../../../components/horizontal-form/HorizontalFormLabel';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import HorizontalFormRow from '../../../components/horizontal-form/HorizontalFormRow';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import HorizontalFormTextAreaField from '../../../components/horizontal-form/HorizontalFormTextAreaField';
import type { MembershipOfferFrontendModel } from '../../../types';
import { dateToCET, isUtcDateInPast } from '../../../../utils/date/dateUtil';
import { validate } from './validator';
import { offerDecorator } from './decorator';
import { OfferSignupPageList } from './OfferSignupPageList.jsx';
import { isSfAccountCanonicalIdValid } from 'app/validation/common/sfIdValidator';
import type { AuthenticatedUser } from '../../../common/authentication/types';
import type { NetworkSettings } from './types';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';

import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import OfferTiersForm from './OfferTiersForm';
import {
  getCurrency,
  getInitialValues,
  getNetworkByCountryCode,
  getWarningEditMessage,
  isUserOpsMaster,
} from './utils';
import { isDev } from '../../../../utils/env/envUtils';
import type { SFCompany } from './useSalesforceCompany';
import { isActiveMembershipOffer, isFutureMembershipOffer } from 'app/utils/offer/offerUtil';

type Props = {
  saveOffer?: Function,
  membershipOffer: MembershipOfferFrontendModel,
  company: SFCompany | undefined,
  loadCompany?: () => Promise<SFCompany>,
  companyLoading: boolean,
  isEditMode: boolean,
  user: AuthenticatedUser,
  networkSettings: NetworkSettings,
};

let timeout = 0;

const regexValidationExternalWebsiteUrl = 'https://www.regextester.com';
const regexValidationSamplesUrl =
  'https://egym.atlassian.net/wiki/spaces/WA/pages/1161199964/RegEx+validation+of+personal+ID+field';

const OfferForm = ({
  saveOffer,
  membershipOffer,
  company,
  loadCompany,
  companyLoading,
  isEditMode,
  user,
  networkSettings,
}: Props) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);
  const lastSfContractFetched = useRef(membershipOffer.sfAccountCanonicalId || '');
  const formRef = useRef();
  const countryCode = company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode;
  const gymlibCountryCodes = window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',');
  const isUsingOfferTierForm = isEditMode ? isDev() : true;

  useEffect(() => {
    formRef.current.mutators.setCompanyDetails(company);
  }, [company]);

  const setCompanyDetails = (company, state) => {
    const newCompany = company?.[0];
    const currentSfAccountCanonicalValue =
      formRef.current.getFieldState('sfAccountCanonicalId').value;
    const companyNameField = state.fields['sfAccountCompanyName'];
    const countryCodeField = state.fields['sfAccountCountryCode'];
    const availableFromField = state.fields['availableFrom'];
    const b2cPaymentField = state.fields['b2cPayment'];
    const deadlineMonth = state.fields['deadlineMonth'];
    const deadlineDay = state.fields['deadlineDay'];
    const amountField = state.fields['amount'];
    const currencyField = state.fields['currency'];
    const networkField = state.fields['network'];

    if (isEditMode) {
      // in edit mode, only autofill company name from salesforce
      // other fields should be filled with membershipOffer's value to avoid being overwritten by salesforce values
      companyNameField.change(newCompany?.name);

      const network = getNetworkByCountryCode(countryCode, networkSettings);
      networkField.change(network || '');
      return;
    }

    if (!newCompany && currentSfAccountCanonicalValue) {
      companyNameField.change('');
      countryCodeField.change('');
      availableFromField.change('');
      b2cPaymentField.change('');
      amountField?.change('');
      currencyField?.change('');
      networkField.change('');
    } else if (currentSfAccountCanonicalValue) {
      companyNameField.change(newCompany.name);
      const countryCode = newCompany.billingAddress && newCompany.billingAddress.countryCode;
      if (countryCode) {
        countryCodeField.change(countryCode);
      }

      const closestContract = newCompany.closestContract;
      const isB2C = closestContract ? closestContract.isB2C : undefined;
      b2cPaymentField.change(isB2C !== undefined ? isB2C : '');
      availableFromField.change(dateToCET(newCompany.startOfContract));
      if (countryCode && gymlibCountryCodes.includes(countryCode)) {
        deadlineMonth.change('0');
        deadlineDay.change('0');
        b2cPaymentField.change(true);
      }
      if (countryCode === 'US') {
        b2cPaymentField.change(false);
      }

      const network = getNetworkByCountryCode(countryCode, networkSettings);
      networkField.change(network || '');
      if (!isUsingOfferTierForm) {
        // to remove once prices are stored per offer tier for edit form too
        amountField.change(closestContract?.b2cPrice);
        currencyField.change(
          getCurrency(
            newCompany,
            newCompany?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
          )
        );
      } else {
        if (closestContract?.b2cPrice) {
          const currentSettings = networkSettings[network];
          const currentOfferSettings = currentSettings?.offers[membershipOffer.type || 'STANDARD'];

          // for now, we can retrieve price from salesforce only DACH market unique formula
          if (network === 'DACH' && currentOfferSettings?.membershipTiers?.length === 1) {
            const uniquePriceField =
              state.fields[`offerTiers.id-${currentOfferSettings.membershipTiers[0].id}.price`];
            if (uniquePriceField) {
              uniquePriceField.change(closestContract.b2cPrice);
            }

            currencyField.change(
              getCurrency(
                newCompany,
                newCompany?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
              )
            );
          }
        }
      }
    }
  };

  const reloadCompany = useCallback(
    sfAccountCanonicalId => {
      if (
        !companyLoading &&
        sfAccountCanonicalId &&
        sfAccountCanonicalId !== lastSfContractFetched.current
      ) {
        lastSfContractFetched.current = sfAccountCanonicalId;
        if (timeout) {
          clearTimeout(timeout);
        }

        if (isSfAccountCanonicalIdValid(sfAccountCanonicalId)) {
          timeout = setTimeout(() => {
            loadCompany(sfAccountCanonicalId, !isEditMode);
          }, 1000);
        }
      }
    },
    [lastSfContractFetched, companyLoading, loadCompany, isEditMode]
  );

  const networkOptions = useMemo(() => offerNetworkOptions(), []);

  const hasInactiveOfferWithMembership = useMemo(
    () =>
      isEditMode &&
      membershipOffer?.membershipOfferTiers?.some(
        offerTier => !isActiveMembershipOffer(offerTier) || !isFutureMembershipOffer(offerTier)
      ),
    [isEditMode, membershipOffer.membershipOfferTiers]
  );

  const isAvailableFromDisabled = useMemo(
    () =>
      !isEditable ||
      hasInactiveOfferWithMembership ||
      isUtcDateInPast(membershipOffer?.availableFrom),
    [isEditable, membershipOffer, hasInactiveOfferWithMembership]
  );

  const showLinkedSignupPages =
    isEditMode &&
    membershipOffer &&
    !gymlibCountryCodes.includes(membershipOffer.sfAccountCountryCode);

  const initialValues = getInitialValues(membershipOffer, company, isEditMode, user);

  return (
    <div className="mt-4">
      <Form
        onSubmit={saveOffer}
        validate={values => validate(isEditMode, values, membershipOffer, isUsingOfferTierForm)}
        initialValuesEqual={() => true}
        initialValues={initialValues}
        decorators={[offerDecorator]}
        mutators={{ setCompanyDetails }}
        render={({ submitError, handleSubmit, submitting, pristine, values, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <h4 className="mt-3 mb-3">{isEditMode ? 'Update offer' : 'Create a offer'}</h4>
              {isEditMode && (
                <div className="card card-body col-xl-10 mb-2">
                  <HorizontalFormRow withoutMargin>
                    <HorizontalFormLabel controlLabel="Offer id" />
                    <HorizontalFormTextField
                      controlId="viewOnlyOfferId"
                      controlLabel="viewOnlyOfferId"
                      placeholder="Offer Id"
                      columnWidth={4}
                      disabled={true}
                      default
                    />
                  </HorizontalFormRow>
                </div>
              )}
              <div className="card card-body col-xl-10 mb-2">
                <h6>Company Information</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Salesforce account canonical ID" />
                  <HorizontalFormTextField
                    controlId="sfAccountCanonicalId"
                    controlLabel="sfAccountCanonicalId"
                    placeholder="SalesForce Account ID"
                    columnWidth={4}
                    disabled={!values.isOfferEditable || isEditMode}
                    helpTextAfter={'Example: ACC-123456'}
                  />
                  <FormSpy
                    subscription={{ dirtyFields: true, values: true }}
                    onChange={props => reloadCompany(props.values.sfAccountCanonicalId)}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Network" />
                  <HorizontalFormDropdownField
                    controlId="network"
                    controlLabel="network"
                    options={networkOptions}
                    columnWidth={4}
                    className="mb-0"
                    disabled
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Salesforce account name" />
                  <HorizontalFormTextField
                    controlId="sfAccountCompanyName"
                    controlLabel="sfAccountCompanyName"
                    placeholder="Company Name"
                    columnWidth={4}
                    loading={companyLoading}
                    disabled
                  />
                </HorizontalFormRow>
                <HorizontalFormRow withoutMargin>
                  <HorizontalFormLabel controlLabel="Salesforce country code" />
                  <HorizontalFormTextField
                    controlId="sfAccountCountryCode"
                    controlLabel="sfAccountCountryCode"
                    placeholder="SalesForce country code"
                    columnWidth={4}
                    loading={companyLoading}
                    disabled
                  />
                </HorizontalFormRow>
              </div>

              <div className="card card-body col-xl-10 mb-2">
                <h6>Offer General Settings</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Offer Type" />
                  <HorizontalFormDropdownField
                    controlId="type"
                    controlLabel="type"
                    options={offerTypeOptions(
                      isUserOpsMaster(user),
                      values.isOfferEditable,
                      values.network
                    )}
                    columnWidth={4}
                    className="mb-0"
                    disabled={isEditMode || !values.isOfferEditable || !isEditable}
                    required={true}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Request user payment details" />
                  <HorizontalFormDropdownField
                    controlId="b2cPayment"
                    controlLabel="Request user payment details"
                    options={requestUserPaymentDetailsOptions()}
                    columnWidth={4}
                    className="mb-2"
                    disabled={
                      countryCode === 'US' ||
                      gymlibCountryCodes.includes(countryCode) ||
                      !values.isOfferEditable ||
                      values.type === 'FREE' ||
                      !isEditable
                    }
                    loading={companyLoading}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow withoutMargin={isUsingOfferTierForm}>
                  <HorizontalFormLabel controlLabel="Available from" />
                  <HorizontalFormTextField
                    controlId="availableFrom"
                    controlLabel="availableFrom"
                    placeholder="DD-MM-YYYY HH:mm"
                    disabled={!values.isOfferEditable || isAvailableFromDisabled}
                    loading={companyLoading}
                    columnWidth={3}
                  />
                  <div className="d-flex flex-wrap mx-1 mt-sm-2 me-1">to</div>
                  <HorizontalFormTextField
                    controlId="availableTo"
                    controlLabel="availableTo"
                    placeholder="DD-MM-YYYY HH:mm"
                    disabled={
                      !values.isOfferEditable ||
                      (membershipOffer.availableTo &&
                        isUtcDateInPast(membershipOffer.availableTo)) ||
                      !isEditable
                    }
                    columnWidth={3}
                  />
                </HorizontalFormRow>
                {
                  // to remove once prices can be edited at offerTier Level
                  !isUsingOfferTierForm ? (
                    <HorizontalFormRow withoutMargin>
                      <HorizontalFormLabel controlLabel="Price Incl VAT & currency" />
                      <HorizontalFormTextField
                        controlId="amount"
                        controlLabel="amount"
                        placeholder="price"
                        columnWidth={2}
                        className="mb-2"
                        disabled={!values.isOfferEditable || values.type === 'FREE' || !isEditable}
                        loading={companyLoading}
                      />
                      <HorizontalFormDropdownField
                        controlId="currency"
                        controlLabel="currency"
                        options={currencyDropdownOptions()}
                        columnWidth={2}
                        className="mb-2"
                        disabled={true}
                      />
                      <small className="price.currency-help-text-after offset-md-3 col-sm-6 px-1">
                        Please be aware that the price is the amount that is paid by the employee.
                        It should not be changed after the company has had first signups. Please
                        double check before editing.
                      </small>
                    </HorizontalFormRow>
                  ) : (
                    <>
                      <OfferTiersForm
                        isEditMode={isEditMode}
                        membershipOffer={membershipOffer}
                        offerType={values.type}
                        network={values.network}
                        networkSettings={networkSettings}
                        loading={companyLoading}
                      />
                      {hasInactiveOfferWithMembership && (
                        <div className="mt-3 flex justify-content-end">
                          <button
                            className="btn d-grid btn-primary text-white col-sm-4 px-2"
                            onChange={() => console.log('todo')}
                          >
                            Move memberships to new prices
                          </button>
                        </div>
                      )}
                    </>
                  )
                }
              </div>
              <div className="card card-body col-xl-10 mb-2">
                <h6>Offer Signup Page Settings</h6>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Company specific Personal Identifier (optional)" />
                  <HorizontalFormTextField
                    controlId="employeeInternalIdentifierLabel"
                    controlLabel="Company specific Personal Identifier (optional)"
                    placeholder=""
                    columnWidth={9}
                    helpTextAfter="If empty, the field asking for personal identifier will not appear on the landing page"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="RegEx validation for Personal Identifier (optional)" />
                  <HorizontalFormTextField
                    controlId="employeeInternalIdentifierValidationRegex"
                    controlLabel="RegEx validation for Personal Identifier (optional)"
                    placeholder=""
                    columnWidth={9}
                    helpTextAfter={
                      <div>
                        <span>If empty, the personal identifier field will not be validated.</span>
                        <br />
                        <span>Please test your validation here </span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={regexValidationExternalWebsiteUrl}
                        >
                          {regexValidationExternalWebsiteUrl}
                        </a>
                        <br />
                        <span>Please find </span>
                        <a target="_blank" rel="noreferrer" href={regexValidationSamplesUrl}>
                          some examples here
                        </a>
                      </div>
                    }
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="RegEx validation Description (optional)" />
                  <HorizontalFormTextAreaField
                    controlId="employeeInternalIdentifierValidationDescription"
                    controlLabel="RegEx validation Description (optional)"
                    placeholder=""
                    columnWidth={9}
                    rows={3}
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Special Conditions (optional)" />
                  <HorizontalFormTextAreaField
                    controlId="customTermsDescription"
                    controlLabel="Special Offer (optional)"
                    columnWidth={9}
                    rows={3}
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                </HorizontalFormRow>
                <HorizontalFormRow>
                  <HorizontalFormLabel controlLabel="Signup deadline" />
                  <HorizontalFormDropdownField
                    controlId="deadlineMonth"
                    controlLabel="deadlineMonth"
                    options={monthsDropdownOptions()}
                    columnWidth={3}
                    className="mb-0"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                  <HorizontalFormDropdownField
                    controlId="deadlineDay"
                    controlLabel="deadlineDay"
                    options={daysDropdownOptions()}
                    columnWidth={3}
                    className="mb-0"
                    disabled={!values.isOfferEditable || !isEditable}
                  />
                  <div className="col-sm-2 px-1 mb-3 justify-content-center d-flex flex-column">
                    <div className=" ">before membership starts</div>
                  </div>
                </HorizontalFormRow>
                <div className="alert alert-info text-black-50 offset-md-3 col-sm-8">{`Next signup deadline will be ${values.nextSignupDeadline} to signup for ${values.nextPossibleMembershipStartDate}`}</div>
                {/* Submit Errors */}
                {submitError && (
                  <div className="alert alert-danger">{JSON.stringify(submitError, null, 2)}</div>
                )}
              </div>
              <div className="mt-3 mb-5">
                {isEditable && (
                  <FormSpy subscription={{ errors: true, dirtyFields: true }}>
                    {({ errors, dirtyFields }) => {
                      const warningMessage = getWarningEditMessage(isEditMode, dirtyFields);
                      return (
                        <>
                          {warningMessage && (
                            <div className="alert alert-warning col-sm-9">{warningMessage}</div>
                          )}
                          <button
                            className="btn d-grid btn-secondary col-sm-3 px-1"
                            type="submit"
                            disabled={
                              !values.isOfferEditable ||
                              submitting ||
                              pristine ||
                              Object.keys(errors).length > 0
                            }
                          >
                            Save
                          </button>
                        </>
                      );
                    }}
                  </FormSpy>
                )}
              </div>
              {showLinkedSignupPages && (
                <div className="card card-body col-xl-10 mb-5">
                  <OfferSignupPageList offerId={membershipOffer.id} company={company} />
                </div>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default OfferForm;
