import type { MembershipOfferFrontendModel, MembershipOfferPayloadV2 } from '../../../types';
import {
  convertToPeriod,
  extractDayFromPeriod,
  extractMonthFromPeriod,
} from '../../../../utils/date/periodUtil';
import { convertCetDateToUtc, dateToCET, isUtcDateInPast, nowInUTC } from 'app/utils/date/dateUtil';
import type { MembershipTier } from './types';
import type { MembershipOfferGetV1, MembershipOfferGetV2 } from '../../../types';

const DEFAULT_CURRENCY = 'EUR';

export const convertMembershipOfferGetV1ToMembershipOfferFrontendModel = (
  membershipOffer: MembershipOfferGetV1 | undefined
): MembershipOfferFrontendModel => ({
  ...membershipOffer,
  amount: membershipOffer?.price ? membershipOffer.price.amount : undefined,
  currency: membershipOffer?.price ? membershipOffer.price.currency : DEFAULT_CURRENCY,
  deadlineDay: membershipOffer ? extractDayFromPeriod(membershipOffer.signupDeadline) : 20,
  deadlineMonth: membershipOffer ? extractMonthFromPeriod(membershipOffer.signupDeadline) : -1,
  sfAccountCanonicalId: membershipOffer?.sfAccountCanonicalId
    ? membershipOffer.sfAccountCanonicalId
    : undefined,
});

export const convertMembershipOfferGetV2ToMembershipOfferFrontendModel = (
  membershipOffer: MembershipOfferGetV2 | undefined
): MembershipOfferFrontendModel => {
  const { membershipOfferTiers, ...offer } = membershipOffer;
  const offerTiers = membershipOfferTiers?.reduce(
    (acc, offerTier) => ({
      ...acc,
      [`id-${offerTier.id}`]: {
        ...offerTier,
        availableFrom: dateToCET(offerTier.availableFrom),
        availableTo: dateToCET(offerTier.availableTo),
      },
    }),
    {}
  );
  return {
    ...offer,
    deadlineDay: membershipOffer ? extractDayFromPeriod(membershipOffer.signupDeadline) : 20,
    deadlineMonth: membershipOffer ? extractMonthFromPeriod(membershipOffer.signupDeadline) : -1,
    sfAccountCanonicalId: membershipOffer?.sfAccountCanonicalId
      ? membershipOffer.sfAccountCanonicalId
      : undefined,
    offerTiers,
  };
};

export const convertMembershipOfferFrontendModelToMembershipOffer = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferFrontendModel => ({
  id: membershipOffer.id,
  b2cPayment: membershipOffer.b2cPayment,
  customTermsDescription: membershipOffer.customTermsDescription,
  employeeInternalIdentifierLabel: membershipOffer.employeeInternalIdentifierLabel,
  employeeInternalIdentifierValidationRegex:
    membershipOffer.employeeInternalIdentifierValidationRegex,
  employeeInternalIdentifierValidationDescription:
    membershipOffer.employeeInternalIdentifierValidationDescription,
  signupDeadline: convertToPeriod(membershipOffer.deadlineMonth, membershipOffer.deadlineDay),
  sfAccountCanonicalId: membershipOffer.sfAccountCanonicalId,
  type: membershipOffer.type,
  availableFrom: convertCetDateToUtc(membershipOffer.availableFrom, 'DD-MM-YYYY HH:mm'),
  availableTo:
    membershipOffer.availableTo !== '' || !membershipOffer.availableTo
      ? convertCetDateToUtc(membershipOffer.availableTo, 'DD-MM-YYYY HH:mm')
      : null,
  sfAccountCountryCode: membershipOffer.sfAccountCountryCode,
});

export const convertMembershipOfferFrontendModelToMembershipOfferV1 = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferFrontendModel => ({
  ...convertMembershipOfferFrontendModelToMembershipOffer(membershipOffer),
  price: {
    amount: membershipOffer.amount,
    currency: membershipOffer.currency.toUpperCase(),
  },
});

export const buildUpdateOfferBody = (offer, backendModel, shouldTerminate) => {
  const updateOfferBody = {
    // Offer ID is necessary for BE validation
    offerId: offer.id,
    sfAccountCanonicalId: backendModel.sfAccountCanonicalId,
    sfAccountCountryCode: backendModel.sfAccountCountryCode,
    employeeInternalIdentifierValidationRegex:
      backendModel.employeeInternalIdentifierValidationRegex
        ? backendModel.employeeInternalIdentifierValidationRegex
        : null,
    employeeInternalIdentifierValidationDescription:
      backendModel.employeeInternalIdentifierValidationDescription
        ? backendModel.employeeInternalIdentifierValidationDescription
        : null,
    availableFrom: backendModel.availableFrom,
    availableTo: !(offer && isUtcDateInPast(offer.availableTo))
      ? backendModel.availableTo
      : undefined,
  };

  if (shouldTerminate) {
    const now = nowInUTC();

    updateOfferBody.availableTo = now;
    updateOfferBody.availableFrom = !isUtcDateInPast(backendModel.availableFrom)
      ? now
      : backendModel.availableFrom;
  }

  return updateOfferBody;
};

export const convertMembershipOfferFormToCreatePayload = (
  membershipOffer: MembershipOfferFrontendModel,
  expectedTiers: MembershipTier[]
): MembershipOfferPayloadV2 => {
  const { offerTiers = {} } = membershipOffer;
  const commonMembershipValues =
    convertMembershipOfferFrontendModelToMembershipOffer(membershipOffer);
  return {
    ...commonMembershipValues,
    network: membershipOffer.network,
    membershipOfferTiers: (expectedTiers || []).map(expectedTier => ({
      membershipTierId: expectedTier.id,
      price: offerTiers[`id-${expectedTier.id}`]?.price,
      currency: membershipOffer.currency.toUpperCase(),
      availableFrom: commonMembershipValues.availableFrom,
      availableTo: commonMembershipValues.availableTo,
    })),
  };
};

export const convertMembershipOfferFormToPatchPayload = (
  membershipOffer: MembershipOfferFrontendModel
): MembershipOfferPayloadV2 => {
  const {
    id,
    type,
    b2cPayment,
    customTermsDescription,
    employeeInternalIdentifierLabel,
    employeeInternalIdentifierValidationRegex,
    employeeInternalIdentifierValidationDescription,
    signupDeadline,
    availableFrom,
    availableTo,
  } = convertMembershipOfferFrontendModelToMembershipOffer(membershipOffer);

  return {
    id,
    type,
    b2cPayment,
    customTermsDescription,
    employeeInternalIdentifierLabel,
    employeeInternalIdentifierValidationRegex,
    employeeInternalIdentifierValidationDescription,
    signupDeadline,
    availableFrom,
    availableTo,
    network: membershipOffer.network,
    membershipOfferTiers: Object.values(membershipOffer.offerTiers).map(offerTier => ({
      ...offerTier,
      availableFrom: offerTier.availableFrom
        ? convertCetDateToUtc(offerTier.availableFrom, 'DD-MM-YYYY HH:mm')
        : null,
      availableTo:
        offerTier.availableTo !== '' || !offerTier.availableTo
          ? convertCetDateToUtc(offerTier.availableTo, 'DD-MM-YYYY HH:mm')
          : null,
      currency: membershipOffer.currency.toUpperCase(),
    })),
  };
};
