// @flow
import React from 'react';
import { Field } from 'react-final-form';
import { resolveErrorCode } from 'app/validation/errorCodeResolver';

const HorizontalFormTextField = ({
  controlId,
  controlLabel,
  placeholder,
  columnWidth,
  helpTextAfter,
  disabled,
  format,
  validateFields,
  loading = false,
  autoFocus = false,
  className = '',
}) => (
  <div key={controlId} className={`col-md-${columnWidth} px-1 ${className}`}>
    <Field name={controlId} type="text" format={format} validateFields={validateFields}>
      {({ input, meta }) => {
        const shouldDisplayError = meta.submitError || (meta.touched && meta.error);
        return (
          <>
            <div className="position-relative">
              <input
                {...input}
                className={`form-control${shouldDisplayError ? ' is-invalid' : ''}`}
                id={controlId}
                type="text"
                placeholder={placeholder}
                aria-describedby={`${controlId}-help-text-after`}
                disabled={disabled}
                autoFocus={autoFocus}
              />

              {loading && (
                <div className="inline-field-spinner">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}
            </div>

            {helpTextAfter && (
              <small className={`${controlId}-help-text-after`}>{helpTextAfter}</small>
            )}
            {shouldDisplayError && (
              <div className="invalid-feedback d-block">
                {resolveErrorCode(meta.submitError || meta.error, controlLabel)}
              </div>
            )}
          </>
        );
      }}
    </Field>
  </div>
);

export default HorizontalFormTextField;
