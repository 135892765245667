import React, { useMemo } from 'react';
import {
  QUALITRAIN_OPS_ADMIN,
  QUALITRAIN_OPS_MASTER,
  userHasAnyRole,
} from 'app/ui/common/authentication/roles';
import AuthenticationContext from 'app/ui/common/authentication/AuthenticationContext';
import type { NetworkSettings, OfferTierField } from './types';
import { MembershipOfferType } from '../../../../utils/offer/offerUtil';
import HorizontalFormTextField from '../../../components/horizontal-form/HorizontalFormTextField';
import { currencyDropdownOptions } from '../../../common/util/dropdownUtil';
import HorizontalFormDropdownField from '../../../components/horizontal-form/HorizontalFormDropdownField';
import { NetworkType } from '../../../gyms/gym/context/PayoutContext';
import {
  formatTierName,
  getExpectedTiers,
  isActiveOfferTier,
  isFutureOfferTier,
  sortTiers,
} from './utils';
import type { MembershipOfferFrontendModel } from '../../../types';

type OfferTiersFormProps = {
  isEditMode: boolean,
  membershipOffer: MembershipOfferFrontendModel | undefined,
  offerType: MembershipOfferType | undefined,
  network: NetworkType | undefined,
  networkSettings: NetworkSettings,
  loading?: boolean,
};

const OfferTiersForm = ({
  isEditMode,
  membershipOffer,
  offerType = 'STANDARD',
  network,
  networkSettings,
  loading = false,
}: OfferTiersFormProps) => {
  const { user: loggedInUser } = React.useContext(AuthenticationContext);
  const isEditable = !!userHasAnyRole(loggedInUser, [QUALITRAIN_OPS_ADMIN, QUALITRAIN_OPS_MASTER]);

  const offerTierFields: Array<OfferTierField> = useMemo(() => {
    const currentNetwork = !network || network === '' ? NetworkType.DACH : network;
    if (!networkSettings) return [];
    const expectedTiers = getExpectedTiers(networkSettings, currentNetwork, offerType);

    if (!isEditMode) {
      return expectedTiers
        .map(tier => ({
          id: tier.id,
          label: formatTierName(tier),
          credits: tier.credits,
          types: tier.types,
          isActive: true,
        }))
        .sort(sortTiers);
    }

    return membershipOffer?.offerTiers
      ? Object.values(membershipOffer.offerTiers)
          .map(offerTier => {
            const tier = expectedTiers.find(tier => tier.id === offerTier.membershipTierId);
            return {
              id: offerTier.id,
              label: formatTierName(tier),
              credits: tier.credits,
              types: tier.types,
              isActive: isActiveOfferTier(offerTier) || isFutureOfferTier(offerTier),
              membershipCount: 0, // TODO: replace with real count data in WPA2-783 (this sprint hopefully)
            };
          })
          .sort(sortTiers)
      : [];
  }, [membershipOffer, networkSettings, isEditMode, network, offerType]);

  if (offerTierFields.length === 0) return;

  return (
    <table className="table mt-2 mb-0">
      <thead className="thead-light">
        <tr>
          <td className="text-primary col-3 font-weight-bold">Offer tiers</td>
          <td className="text-primary font-weight-bold">Price</td>
          {isEditMode && (
            <>
              <td className="text-primary font-weight-bold">Available from / to</td>
              {/*<td className="text-primary font-weight-bold">Memberships</td>*/}
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {offerTierFields.map(offerTier => (
          <tr className={!offerTier.isActive ? 'disabled-row' : undefined} key={offerTier.id}>
            <td
              className="align-content-center col-3 text-muted text-monospace"
              style={{ fontSize: '0.9rem' }}
            >
              {offerTier.label}
            </td>
            <td>
              <div className="d-flex">
                <HorizontalFormTextField
                  controlId={`offerTiers.id-${offerTier.id}.price`}
                  controlLabel={`offerTiers.id-${offerTier.id}.price`}
                  placeholder="Price"
                  className="mb-0 flex-fill"
                  disabled={!isEditable || !offerTier.isActive || membershipOffer.type === 'FREE'}
                  loading={loading}
                />
                {/* all currency fields are targeting same unique value */}
                <HorizontalFormDropdownField
                  controlId={`currency`}
                  controlLabel={`currency`}
                  options={currencyDropdownOptions()}
                  className="mb-0 flex-fill"
                  disabled={true}
                  style={{ minWidth: '100px' }}
                />
              </div>
            </td>
            {isEditMode && (
              <>
                <td>
                  <div className="d-flex">
                    <HorizontalFormTextField
                      controlId={`offerTiers.id-${offerTier.id}.availableFrom`}
                      controlLabel={`offerTiers.id-${offerTier.id}.availableFrom`}
                      placeholder="DD-MM-YYYY HH:mm"
                      className="mb-0 flex-fill"
                      loading={loading}
                      disabled
                    />
                    <HorizontalFormTextField
                      controlId={`offerTiers.id-${offerTier.id}.availableTo`}
                      controlLabel={`offerTiers.id-${offerTier.id}.availableTo`}
                      placeholder="DD-MM-YYYY HH:mm"
                      className="mb-0 flex-fill"
                      loading={loading}
                      disabled
                    />
                  </div>
                </td>
                {/*
                Same will be implement in scope of WPA2-783
                <td
                    className="align-content-center text-muted text-monospace"
                    style={{ fontSize: '0.9rem' }}
                  >
                    {!offerTier.isActive && offerTier.membershipCount > 0 && (
                      <i className="fa text-danger fa-exclamation-triangle me-2" />
                    )}
                    {offerTier.membershipCount}
                  </td>*/}
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OfferTiersForm;
