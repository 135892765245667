// @flow
import * as React from 'react';
import type { MembershipOfferFrontendModel } from '../../../types';

type OfferContextState = {
  offer: MembershipOfferFrontendModel | undefined,
  setOffer: (offer: MembershipOfferFrontendModel | undefined) => void,
  isEditMode: boolean,
  setIsEditMode: (isEditMode: boolean) => void,
};

const OfferContext = React.createContext<OfferContextState>({
  offer: undefined,
  setOffer: () => {},
  isEditMode: true,
  setIsEditMode: () => {},
});

const OfferContextProvider = ({ children }) => {
  const [isEditMode, setIsEditMode] = React.useState(true);
  const [offer, setOffer] = React.useState<MembershipOfferFrontendModel | undefined>(undefined);

  const contextValue = React.useMemo(
    () => ({ isEditMode, setIsEditMode, offer, setOffer }),
    [isEditMode, offer]
  );

  return <OfferContext.Provider value={contextValue}>{children}</OfferContext.Provider>;
};

export { OfferContextProvider };
export default OfferContext;
