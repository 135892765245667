import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  buildUpdateOfferBody,
  convertMembershipOfferFormToCreatePayload,
  convertMembershipOfferFormToPatchPayload,
  convertMembershipOfferFrontendModelToMembershipOfferV1,
} from './converter';
import { httpPatch, httpPost } from '../../../../service/http';
import { getExpectedTiers } from './utils';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import OfferContext from '../context/OfferContext';
import NetworkSettingsContext from '../context/NetworkSettingsContext';
import { useNavigate } from 'react-router-dom';
import type { SFCompany } from './useSalesforceCompany';
import { isDev } from '../../../../utils/env/envUtils';

type UseSaveOfferProps = {
  company: SFCompany | undefined,
};

export const useSaveOffer = ({ company }: UseSaveOfferProps) => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { networkSettings } = useContext(NetworkSettingsContext);
  const { isEditMode, offer } = React.useContext(OfferContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const updateOffer = useCallback(
    (updatedOffer, form) => {
      const updateOfferPageV1 = async () => {
        try {
          const backendModel = convertMembershipOfferFrontendModelToMembershipOfferV1(updatedOffer);
          const shouldEndExistingOffer =
            form.getState().dirtyFields['amount'] ||
            form.getState().dirtyFields['currency'] ||
            form.getState().dirtyFields['b2cPayment'] ||
            form.getState().dirtyFields['customTermsDescription'] ||
            form.getState().dirtyFields['employeeInternalIdentifierLabel'] ||
            form.getState().dirtyFields['deadlineDay'] ||
            form.getState().dirtyFields['type'] ||
            form.getState().dirtyFields['deadlineMonth'];

          const payload = buildUpdateOfferBody(offer, backendModel, shouldEndExistingOffer);
          await httpPatch(`/v1/membership-offer/${backendModel.id}`, payload);

          if (shouldEndExistingOffer) {
            const newMembership = await httpPost(
              `/v1/membership-offer/membershipOfferSignupPageUpdate`,
              backendModel
            );
            //Reload the path but with the new offer id so ops have access to this offer url
            window.location.href = `${window.location.protocol}//${window.location.host}/companies/offer-manager/${newMembership.id}/details`;
          }
        } catch (error) {
          setError(error);
        }
      };

      const updateOfferPageV2 = async () => {
        const payload = convertMembershipOfferFormToPatchPayload(updatedOffer);
        try {
          const editedOffer = await httpPatch(`/admin/v2/membership-offer/${payload.id}`, payload);
          // Reload ops to edited offer from this patch (editedOffer id can be different from payload.id in case offer was terminated)
          window.location.href = `${window.location.protocol}//${window.location.host}/companies/offer-manager/${editedOffer.id}/details`;
        } catch (error) {
          console.error(error);
        }
      };

      if (isDev()) {
        return executeWithSpinner(updateOfferPageV2());
      }
      // to remove once prices can be edited at offerTier Level
      return executeWithSpinner(updateOfferPageV1());
    },
    [offer, setError, executeWithSpinner]
  );

  const createOffer = useCallback(
    offer => {
      const expectedTiers = getExpectedTiers(networkSettings, offer.network, offer.type);
      const offerPayload = convertMembershipOfferFormToCreatePayload(offer, expectedTiers);
      const createOfferPage = async () => {
        try {
          const createdOffer = await httpPost(`/admin/v2/membership-offer`, offerPayload);

          const gymlibOffer = window._env_.REACT_APP_GYMLIB_COUNTRY_CODES.split(',').includes(
            offerPayload.sfAccountCountryCode
          );
          if (gymlibOffer) {
            //Reload the path but with the new offer id so ops have access to this offer url
            window.location.href = `${window.location.protocol}//${window.location.host}/companies/offer-manager/${createdOffer.id}/details`;
          } else {
            //continue with singup page creation
            navigate('/companies/self-signup-manager', {
              state: {
                name: company?.name,
                address: company?.billingAddress,
                offerId: createdOffer.id,
              },
            });
          }
        } catch (error) {
          setError(error);
        }
      };
      return executeWithSpinner(createOfferPage());
    },
    [setError, networkSettings, executeWithSpinner, company, navigate]
  );

  return useMemo(
    () => ({ saveOffer: isEditMode ? updateOffer : createOffer, error }),
    [isEditMode, updateOffer, createOffer, error]
  );
};
