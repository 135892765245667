import type { AuthenticatedUser } from '../../../common/authentication/types';
import { QUALITRAIN_OPS_MASTER } from '../../../common/authentication/roles';
import { SFCompany } from './types';
import type { MembershipTier, NetworkSettings, OfferTierField } from './types';
import { MembershipOfferType } from '../../../../utils/offer/offerUtil';
import type { MembershipOfferFrontendModel } from '../../../types';
import {
  dateToCET,
  getNextPossibleMembershipStartDate,
  getNextSignupDeadline,
} from '../../../../utils/date/dateUtil';
import moment from 'moment/moment';

export const isUserOpsMaster = (user: AuthenticatedUser) => {
  return user ? user.roles.some(role => role.authority === QUALITRAIN_OPS_MASTER) : false;
};

export const getCurrency = (company: SFCompany, countryCode) => {
  const contract = company?.closestContract;
  if (!contract?.currency) {
    switch (countryCode) {
      case 'US':
        return 'USD';
      default:
        return 'EUR';
    }
  }
  return contract.currency;
};

export const getNetworkByCountryCode = (
  countryCode: string,
  networkSettings: NetworkSettings = []
) => {
  return Object.keys(networkSettings).find(network => {
    if (networkSettings[network]?.countries?.length > 0)
      return networkSettings[network].countries.includes(countryCode);
    return null;
  });
};

export const sortTiers = (a: OfferTierField, b: OfferTierField) => {
  if (a.credits < b.credits && a.types.length <= b.types.length) return -1;
};

export const formatTierName = (tier: MembershipTier) => {
  const formattedTypes = tier.types.sort().join(' + ').toUpperCase();
  return tier.credits > 0 ? `${tier.credits} ${formattedTypes}` : formattedTypes;
};

export const getExpectedTiers = (
  networkSettings: NetworkSettings,
  network: NetworkType,
  offerType: MembershipOfferType | undefined
): MembershipTier[] => {
  const networkSetting = networkSettings[network];
  if (!networkSetting || !networkSetting.offers || !networkSetting.offers[offerType]) return [];
  return networkSetting.offers[offerType].membershipTiers || [];
};

export const getInitialValues = (
  membershipOffer: MembershipOfferFrontendModel,
  company: SFCompany | undefined,
  editMode: boolean,
  user: AuthenticatedUser
) => {
  return {
    ...membershipOffer,
    type: membershipOffer.type || 'STANDARD',
    sfAccountCompanyName: company?.name || '',
    sfAccountCountryCode:
      company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode,
    nextPossibleMembershipStartDate: getNextPossibleMembershipStartDate(
      moment(),
      parseInt(membershipOffer.deadlineMonth),
      parseInt(membershipOffer.deadlineDay)
    ).format('DD-MM-YYYY'),
    nextSignupDeadline: getNextSignupDeadline(
      moment(),
      parseInt(membershipOffer.deadlineDay)
    ).format('DD-MM-YYYY'),
    signUpPageExpirationDate: dateToCET(membershipOffer.expiration),
    availableFrom: editMode ? dateToCET(membershipOffer.availableFrom) : '',
    availableTo: dateToCET(membershipOffer.availableTo),
    isOfferEditable: membershipOffer.type === 'PLUS_ONE' ? isUserOpsMaster(user) : true,
    viewOnlyOfferId: editMode && membershipOffer ? membershipOffer.id : '',
    currency: getCurrency(
      company,
      company?.billingAddress?.countryCode || membershipOffer.sfAccountCountryCode
    ),
    network: membershipOffer.network || '',
  };
};

export const getWarningEditMessage = (
  isEditMode: boolean,
  dirtyFields: { [key: string]: boolean }
) => {
  if (!isEditMode) return null;
  const dirtyFieldsKeys = Object.keys(dirtyFields);
  const isOfferTierPriceEdited = dirtyFieldsKeys.some(
    key => key.includes('offerTiers') && key.includes('price')
  );
  const isOfferTypeOrB2CPaymentEdited = dirtyFieldsKeys.some(
    key => key.includes('type') || key.includes('b2cPayment')
  );
  if (isOfferTypeOrB2CPaymentEdited)
    return 'Changing the offer type or B2C payment will terminate the offer and recreate one. Are you sure you want to proceed?';
  if (isOfferTierPriceEdited)
    return 'Changing the offer tier price will terminate and recreate every offer tiers you edited. Are you sure you want to proceed?';
};

export const isActiveOfferTier = (membershipOffer, now = moment()) => {
  if (!membershipOffer) return false;

  const availableFrom = membershipOffer.availableFrom
    ? moment(membershipOffer.availableFrom, 'DD-MM-YYYY HH:mm')
    : null;
  const availableTo = membershipOffer.availableTo
    ? moment(membershipOffer.availableTo, 'DD-MM-YYYY HH:mm')
    : null;

  return (
    (!availableFrom || availableFrom.isSameOrBefore(now)) &&
    (!availableTo || availableTo.isSameOrAfter(now))
  );
};

export const isFutureOfferTier = (membershipOffer, now = moment()) => {
  if (!membershipOffer) return false;

  const availableFrom = membershipOffer.availableFrom
    ? moment(membershipOffer.availableFrom, 'DD-MM-YYYY HH:mm')
    : null;
  const availableTo = membershipOffer.availableTo
    ? moment(membershipOffer.availableTo, 'DD-MM-YYYY HH:mm')
    : null;

  return availableFrom && availableFrom.isAfter(now) && (!availableTo || availableTo.isAfter(now));
};
