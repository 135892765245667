import * as React from 'react';
import { useCallback, useEffect, useContext } from 'react';
import OfferForm from './OfferForm';
import SpinnerContext from '../../../common/spinner/SpinnerContext';
import AuthenticationContext from '../../../common/authentication/AuthenticationContext';
import { useLocation, useParams } from 'react-router-dom';
import OfferContext from '../context/OfferContext';
import { useSaveOffer } from './useSaveOffer';
import { useSalesforceCompany } from './useSalesforceCompany';
import NetworkSettingsContext from '../context/NetworkSettingsContext';

const OfferManager = () => {
  const { executeWithSpinner } = useContext(SpinnerContext);
  const { user } = useContext(AuthenticationContext);
  const { isEditMode, offer, setOffer } = React.useContext(OfferContext);
  const { networkSettings, isLoading: isNetworkSettingsLoading } =
    useContext(NetworkSettingsContext);
  const paramsOfferId = useParams().offerId;

  const { company, companyLoading, fetchCompany, error: companyError } = useSalesforceCompany();
  const { saveOffer, error: saveError } = useSaveOffer({ company });

  const location = useLocation();
  const paramsSfAccountId = location.state?.sfAccountId;

  const getCompanyInformation = useCallback(
    (sfAccountCanonicalId, refreshCache = false) => {
      if (!sfAccountCanonicalId) {
        return;
      }
      fetchCompany(sfAccountCanonicalId, refreshCache);
    },
    [fetchCompany]
  );

  useEffect(() => {
    if (offer !== undefined && paramsOfferId && offer.sfAccountCanonicalId !== undefined) {
      getCompanyInformation(offer.sfAccountCanonicalId);
    } else if (
      !isEditMode &&
      paramsOfferId !== undefined &&
      offer.sfAccountCanonicalId === undefined
    ) {
      if (paramsSfAccountId) {
        getCompanyInformation(paramsSfAccountId, true);
        offer.sfAccountCanonicalId = paramsSfAccountId;
        setOffer(offer);
      }
    }
  }, [
    offer,
    setOffer,
    paramsOfferId,
    getCompanyInformation,
    isEditMode,
    executeWithSpinner,
    paramsSfAccountId,
  ]);

  return (
    <>
      {companyError && <div className="alert alert-warning">{companyError}</div>}
      {saveError && (
        <>
          {saveError.userServiceGeneralErrors ? (
            <ul className="list-group pb-2">
              {saveError.userServiceGeneralErrors.map(generalError => (
                <li
                  key={`${generalError.code}`}
                  className="list-group-item list-group-item-warning"
                >
                  {`${generalError.message || generalError.defaultMessage}`}
                </li>
              ))}
            </ul>
          ) : (
            <div className="alert alert-warning">
              {saveError.message || JSON.stringify(saveError)}
            </div>
          )}
        </>
      )}
      {offer && !isNetworkSettingsLoading && (
        <OfferForm
          saveOffer={saveOffer}
          membershipOffer={offer}
          company={company}
          networkSettings={networkSettings}
          loadCompany={getCompanyInformation}
          companyLoading={companyLoading}
          isEditMode={isEditMode}
          user={user}
        />
      )}
    </>
  );
};

export default OfferManager;
