// @flow
import React from 'react';

type Props = {
  withoutMargin?: boolean,
  children: any,
};

const HorizontalFormRow = ({ withoutMargin, children }: Props) => (
  <div className={`d-flex flex-wrap mx-1 mb-${withoutMargin ? 0 : 3}`}>{children}</div>
);
export default HorizontalFormRow;
