// @flow
import React, { useEffect } from 'react';
import { isDev } from 'app/utils/env/envUtils';
import { NavLink, useParams } from 'react-router-dom';
import { withPageTemplate } from 'app/ui/layout/PageTemplate';
import SpinnerContext from 'app/ui/common/spinner/SpinnerContext';
import OfferContext, { OfferContextProvider } from '../context/OfferContext';
import { NetworkSettingsContextProvider } from '../context/NetworkSettingsContext';
import OfferManagerSubRoutes from './OfferManagerSubRoutes';
import { httpGet } from '../../../../service/http';
import {
  convertMembershipOfferGetV1ToMembershipOfferFrontendModel,
  convertMembershipOfferGetV2ToMembershipOfferFrontendModel,
} from '../offer-details/converter';

type SubRouteLinkProps = {
  path: string,
  text: string,
};

const SubRouteLink = ({ path, text }: SubRouteLinkProps) => (
  <NavLink className={({ isActive }) => `nav-item nav-link ${isActive ? ' active' : ''}`} to={path}>
    {text}
  </NavLink>
);

const OfferManagerTemplateWithSubRoutes = () => {
  const { setOffer, setIsEditMode } = React.useContext(OfferContext);
  const { executeWithSpinner } = React.useContext(SpinnerContext);
  const offerId = useParams().offerId;

  useEffect(() => {
    const getOfferAndFeatures = async () => {
      try {
        if (isDev()) {
          const offerResponse = await httpGet(`/admin/v2/membership-offer/${offerId}`);
          setOffer(convertMembershipOfferGetV2ToMembershipOfferFrontendModel(offerResponse));
        } else {
          // to remove once prices can be edited at offerTier Level
          const offerResponse = await httpGet(`/v1/membership-offer/${offerId}`);
          setOffer(convertMembershipOfferGetV1ToMembershipOfferFrontendModel(offerResponse));
        }
      } catch (error) {
        setOffer(undefined);
      }
    };

    if (offerId === 'new') {
      setIsEditMode(false);
      setOffer(convertMembershipOfferGetV1ToMembershipOfferFrontendModel(undefined));
    } else {
      setIsEditMode(true);
      executeWithSpinner(getOfferAndFeatures());
    }
  }, [executeWithSpinner, setIsEditMode, offerId, setOffer]);

  return (
    <>
      {offerId !== 'new' && (
        <nav className="nav nav-tabs flex-column flex-sm-row justify-content-center">
          <SubRouteLink path={`/companies/offer-manager/${offerId}/details`} text="Offer Details" />
        </nav>
      )}
      <div className="mt-4">
        <OfferManagerSubRoutes />
      </div>
    </>
  );
};

const OfferManagerTemplateWithContext = props => (
  <OfferContextProvider>
    <NetworkSettingsContextProvider>
      <OfferManagerTemplateWithSubRoutes {...props} />
    </NetworkSettingsContextProvider>
  </OfferContextProvider>
);

export default withPageTemplate(OfferManagerTemplateWithContext, { headerTitle: 'Offer Manager' });
